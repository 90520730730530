import styled, { css } from 'styled-components'

const Background = styled.div.attrs<{
  $color?: string,
  $textColor?: string,
}>( p => ( {
  className: `${ p.$color ? `bx-background--color_${ p.$color }` : '' }`,
} ) )<{
  $color?: string,
  $textColor?: string,
}>`
  ${ p => p.$color && css`
    background: ${ p.theme.getColor( p.$color ) };
  ` }

  ${ p => p.$textColor && css`
    color: ${ p.theme.getColor( p.$textColor ) };
  ` }
`

export default Background
