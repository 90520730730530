import Link from '../Link'

export default function NavBarTextLink( { 
  url,
  text,
  target, 
} ) {
  return (
    <Link 
      href={ url }
      target={ target }
    >
      { text }
    </Link>
  )
}
