function MenuIcon( { title = null } ) {
  return (
    <svg 
      width="25"
      height="14"
      viewBox="0 0 25 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      { title && (
        <title>
          { title }
        </title>
      ) }
      <rect 
        y="0.100098"
        width="25"
        height="1"
        fill="#323E48"
      />
      <rect 
        y="6.5"
        width="25"
        height="1"
        fill="#323E48"
      />
      <rect 
        y="12.8999"
        width="25"
        height="1"
        fill="#323E48"
      />
    </svg>
  )
}

export default MenuIcon
