import { ReactNode, useRef, useState } from 'react'
import styled, { css } from 'styled-components'
import useModalTraits from '../hooks/useModalTraits'
import Button, { UnstyledButton } from './Button'
import type { TUnstyledButton } from './Button'

interface Dropdown {
  toggler: ReactNode,
  dropdownItems: Array<TUnstyledButton & {
    id: number,
    withSeparator: boolean,
  }>,
  closeOnSelect?: boolean,
}

export default function Dropdown( { toggler, dropdownItems, closeOnSelect = true }: Dropdown ) {
  const containerRef = useRef( null )
  const [isOpen, setIsOpen] = useState( false )

  useModalTraits( {
    containerRef,
    isOpen,
    close: () => setIsOpen( false ),
    preventBodyScroll: false,
  } )
  
  return (
    <StyledDropdown ref={ containerRef }>
      <Toggler 
        toggler={ toggler } 
        toggleDropdown={ () => setIsOpen( s => !s ) }
      />
      <StyledDropdownList 
        $isOpen={ isOpen }
        onClick={ () => closeOnSelect && setIsOpen( false ) }
      >
        { dropdownItems.map( item => (
          <StyledItem 
            key={ item.id }
            $withSeparator={ item.withSeparator }
          >
            <DropdownItem
              onClick={ item.onClick }
              href={ item.href }
              target={ item.target }
            >
              { item.children }
            </DropdownItem>
          </StyledItem>
        ) ) }
      </StyledDropdownList>
    </StyledDropdown>
  )
}

function Toggler( { toggler, toggleDropdown } ) {
  if ( typeof toggler === 'string' ) {
    return (
      <Button onClick={ toggleDropdown }>
        { toggler }
      </Button>
    )
  }

  return (
    <StyledWrapperButton
      type="button"
      onClick={ toggleDropdown }
    >
      { toggler }
    </StyledWrapperButton>
  )
}

function DropdownItem( {
  onClick = null,
  href = null,
  target = null,
  children = null,
} ) {
  if ( onClick ) {
    return (
      <UnstyledButton
        onClick={ onClick } 
      >
        { children }
      </UnstyledButton>
    )
  }

  return (
    <UnstyledButton
      href={ href } 
      target={ target }
    >
      { children }
    </UnstyledButton>
  )
}

const StyledDropdown = styled.div`
  position: relative;
`

const StyledDropdownList = styled.div<{
  $isOpen: boolean,
}>`
  overflow: hidden;
  position: absolute;
  right: 0;
  top: calc( 100% + ( 2 * var( --stack-basis)) );
  width: max-content;
  min-width: 250px;
  max-width: 80vw;
  border-radius: var( --card-border-radius );
  box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.12);
  background: ${ p => p.theme.colors.realWhite };
  opacity: 0;
  pointer-events: none;
  transition: opacity .3s ease-out;

  ${ p => p.$isOpen && css`
    opacity: 1;
    pointer-events: all;
  ` }
`

const StyledItem = styled.div<{ $withSeparator: boolean }>`
  display: block;
  background: ${ p => p.theme.colors.realWhite };

  a,
  button {
    display: block;
    box-sizing: border-box;
    width: 100%;
    padding: 10px 12px;
    border: 0;
    background: ${ p => p.theme.colors.realWhite };
    font-size: ${ p => p.theme.typo.sizes.small };
    text-align: left;
    text-decoration: none;
    color: inherit;
    cursor: pointer;
    transition: background .3s ease-out;

    &:hover {
      background: ${ p => p.theme.colors.green100 };
    }
  }

  ${ p => p.$withSeparator && css`
    border-bottom: 1px solid ${ p.theme.colors.grey200 };
  ` }
`

const StyledWrapperButton = styled.button`
  all: unset;
  cursor: pointer;
`
