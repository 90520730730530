import Router, { useRouter } from 'next/router'
import { ParsedUrlQuery } from 'querystring'
import styled from 'styled-components'
import Dropdown from '../Dropdown'
import MenuIcon from '../icons/MenuIcon'
import UserNavIcon from '../icons/UserNavIcon'

export default function NavBarDropdown( { label, icon, dropdownList } ) {
  const {
    locales, locale, pathname, asPath, query, push, 
  } = useRouter()

  return (
    <StyledNavBarDropdown>
      <Dropdown
        toggler={ (
          <StyledToggle>
            <Toggle
              label={ label }
              icon={ icon }
            />
          </StyledToggle>
        ) }
        dropdownItems={ dropdownList.map( item => dropdownItem( {
          item,
          locales,
          locale,
          pathname,
          asPath,
          query,
          push,
        } ) ) }
      />
    </StyledNavBarDropdown>
  )
}

function Toggle( { icon, label } ) {
  if ( icon === 'user' ) {
    return (
      <UserNavIcon title={ label } />
    )
  }
  
  if ( icon === 'hamburger' ) {
    return (
      <MenuIcon title={ label } />
    )
  }

  return label
}

type TItem = {
  id: string,
  linkIt: {
    url: string,
    target: '_blank' | '_self',
    text: string,
  }
  withSeparator: boolean,
}

interface IDropdownItem {
  item: TItem,
  locales: Array<string>,
  locale: string,
  pathname: string,
  asPath: string,
  query: ParsedUrlQuery,
  push: typeof Router.push,
}

function dropdownItem( {
  item, 
  locales, 
  locale, 
  pathname, 
  asPath, 
  query, 
  push,
}: IDropdownItem ) {
  const langSwitchPattern = new RegExp( `/{lang:(?:\\s*)(${ locales.join( '|' ) })}` )
  const langSwitchMatches = item.linkIt.url.match( langSwitchPattern )

  if ( langSwitchMatches?.length ) {
    const newLocale = langSwitchMatches[ 1 ]

    return {
      id: item.id,
      onClick: () => {
        if ( newLocale !== locale ) {
          push( { pathname, query }, asPath, { locale: newLocale } )
        }
      },
      withSeparator: item.withSeparator,
      children: item.linkIt.text,
    }
  }

  return {
    id: item.id,
    href: item.linkIt.url,
    target: item.linkIt.target,
    withSeparator: item.withSeparator,
    children: item.linkIt.text,
  }
}

export const StyledNavBarDropdown = styled.div`
  
`

const StyledToggle = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 40px;
  width: 40px;
  border-radius: 100%;
  border: 1px solid ${ p => p.theme.colors.green400 };
  transition: background-color .3s ease-out;

  svg {
    width: 16px;

    path {
      transition: stroke .3s ease-out;
    }

    rect {
      transition: fill .3s ease-out;
    }
  }

  button:hover & {
    background: ${ p => p.theme.colors.green400 };

    svg {
      path {
        stroke: white;
      }

      rect {
        fill: white;
      }
    }
  }
`
