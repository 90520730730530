export default function UserNavIcon( { title } ) {
  return (
    <svg 
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      { title && <title>{ title }</title> }
      <path d="M1.75391 15C1.75391 11.5962 4.51319 8.83696 7.91695 8.83696C11.3207 8.83696 14.08 11.5962 14.08 15M11.4169 4.42391C11.4169 6.31489 9.88401 7.84783 7.99304 7.84783C6.10206 7.84783 4.56912 6.31489 4.56912 4.42391C4.56912 2.53294 6.10206 1 7.99304 1C9.88401 1 11.4169 2.53294 11.4169 4.42391Z" stroke="#009877" />
    </svg>

  )
}
