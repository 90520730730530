import styled, { css } from 'styled-components'

function BrixelLogo( { breakpoint = null } ) {
  return (
    <StyledBrixelLogo $breakpoint={ breakpoint }>
      <svg
        width="99"
        height="33"
        viewBox="0 0 99 33"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <MarquePaths />
        <TextPaths />
      </svg>
      <svg
        width="33"
        height="33"
        viewBox="0 0 33 33"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <MarquePaths />
      </svg>
    </StyledBrixelLogo>
  )
}

function MarquePaths() {
  return (
    <>
      <path d="M15.4105 0.320801H0V15.8401H15.4105V0.320801Z" fill="#009877" />
      <path d="M24.4011 0.15918C20.0556 0.15918 16.5342 3.70549 16.5342 8.08169C16.5342 12.4555 20.0556 16.0042 24.4011 16.0042C28.7442 16.0042 32.2681 12.4579 32.2681 8.08169C32.2657 3.70549 28.7442 0.15918 24.4011 0.15918Z" fill="#FF595A" />
      <path d="M24.5614 17.1333H0V32.6526H24.5614C28.8159 32.6526 32.2679 29.1787 32.2679 24.8918C32.2655 20.6072 28.8159 17.1333 24.5614 17.1333Z" fill="#00C1DE" />
    </>
  )
}

function TextPaths() {
  return (
    <>
      <path d="M54.0527 17.3558C53.5264 16.6763 52.828 16.2414 51.9542 16.0545C52.7201 15.8677 53.3375 15.4735 53.8098 14.8722C54.2821 14.2708 54.5149 13.4962 54.5149 12.5516C54.5149 11.3319 54.1235 10.3874 53.3375 9.71465C52.5514 9.04193 51.4279 8.70557 49.9671 8.70557H43.8809V23.8826H50.0886C51.6304 23.8826 52.8112 23.5122 53.6209 22.7749C54.4339 22.0377 54.8388 21.0286 54.8388 19.7545C54.8422 18.8338 54.579 18.0353 54.0527 17.3558ZM46.7722 11.1314H49.3328C50.0987 11.1314 50.6655 11.3047 51.0332 11.6479C51.4009 11.991 51.5865 12.4803 51.5865 13.1088C51.5865 13.7374 51.3976 14.2232 51.0231 14.5596C50.6486 14.896 50.0987 15.0658 49.3733 15.0658H46.7722V11.1314ZM51.2761 20.8791C50.8679 21.229 50.2775 21.4057 49.515 21.4057H46.7688V17.2369H49.4948C50.2606 17.2369 50.851 17.4204 51.266 17.7839C51.681 18.1508 51.8901 18.6673 51.8901 19.3434C51.8935 20.0161 51.6877 20.5291 51.2761 20.8791Z" fill="#06262D" />
      <path d="M59.6456 15.0792C60.047 14.3861 60.5801 13.8391 61.2447 13.4382C61.9093 13.0373 62.9282 12.4937 63.7447 12.4937L63.7379 15.3272C61.7238 15.3272 59.6422 16.9581 59.6422 19.4757V23.8755H56.8926V12.8402H59.6422V15.0792H59.6456Z" fill="#06262D" />
      <path d="M68.5432 12.8403V23.8791H65.8037V12.8403H68.5432Z" fill="#06262D" />
      <path d="M78.5052 23.8791L76.0491 20.563L73.7718 23.8791H70.5938L74.4668 18.3138L70.5971 12.8403H73.7516L76.0525 16.1088L78.4816 12.8403H81.5551L77.655 18.3172L81.5551 23.8791H78.4006H78.5052Z" fill="#06262D" />
      <path d="M98.6809 8.70215V23.8791H95.9414V8.70215H98.6809Z" fill="#06262D" />
      <path d="M67.1728 11.8074C68.1194 11.8074 68.8867 11.0347 68.8867 10.0814C68.8867 9.12821 68.1194 8.35547 67.1728 8.35547C66.2263 8.35547 65.459 9.12821 65.459 10.0814C65.459 11.0347 66.2263 11.8074 67.1728 11.8074Z" fill="#06262D" />
      <path d="M89.8207 21.3953C89.3753 21.7249 88.8153 21.8914 88.1439 21.8914C87.3882 21.8914 86.7573 21.6502 86.2512 21.1677C85.7418 20.6852 85.4753 20.0669 85.4348 19.051H93.7713C93.8388 18.6807 93.8894 18.2152 93.8894 17.9264C93.8894 16.8799 93.66 15.949 93.2012 15.1302C92.7423 14.3114 92.0878 13.6692 91.2444 13.2004C90.401 12.7315 89.4158 12.4971 88.289 12.4971C87.1756 12.4971 86.1905 12.7315 85.3235 13.2004C84.4598 13.6692 83.785 14.3454 83.306 15.2355C82.8269 16.1223 82.584 17.1653 82.584 18.3647C82.584 19.5606 82.8235 20.6003 83.306 21.4837C83.785 22.3636 84.4598 23.0432 85.3235 23.5188C86.1871 23.9945 87.1756 24.2323 88.289 24.2323C89.237 24.2323 90.0973 24.0522 90.8733 23.6955C91.6492 23.3387 92.2835 22.8427 92.7761 22.2074C93.191 21.6739 93.4846 21.0862 93.6566 20.4372H90.5629C90.4077 20.8211 90.1614 21.1405 89.8207 21.3953ZM86.3086 15.5107C86.845 15.0283 87.5029 14.787 88.2856 14.787C89.0548 14.787 89.7026 15.0283 90.2323 15.5107C90.6304 15.8709 90.8665 16.3635 90.9408 16.9819C91.0622 16.9819 86.8214 16.9819 85.5124 16.9819C85.6406 16.3635 85.9071 15.8743 86.3086 15.5107Z" fill="#06262D" />
    </>
  )
}

const StyledBrixelLogo = styled.div<{
  $breakpoint?: number,
}>`
  svg {
    &:first-child {
      display: block;
    }

    &:last-child {
      display: none;
    }
  }

  ${ p => p.$breakpoint && css`
    @media ( max-width: ${ p.$breakpoint }px ) {
      svg {
        &:first-child {
          display: none;
        }

        &:last-child {
          display: block;
        }
      }
    }
  ` }
`

export default BrixelLogo
