import styled from 'styled-components'
import NavBarButton from './NavBarButton'
import NavBarDropdown, { StyledNavBarDropdown } from './NavBarDropdown'
import NavBarTextLink from './NavBarTextLink'

export default function NavigationBar( { navigationBar } ) {
  return (
    <StyledNavigationBar>
      { navigationBar.map( navBarItem => {
        if ( navBarItem.typeHandle === 'button' ) {
          return (
            <NavBarButton
              key={ navBarItem.id }
              url={ navBarItem.linkIt.url }
              text={ navBarItem.linkIt.text }
              target={ navBarItem.linkIt.target }
              buttonStyle={ navBarItem.buttonStyle }
              color={ navBarItem.buttonColor }
            />
          )
        }
        
        if ( navBarItem.typeHandle === 'textLink' ) {
          return (
            <NavBarTextLink
              key={ navBarItem.id }
              url={ navBarItem.linkIt.url }
              text={ navBarItem.linkIt.text }
              target={ navBarItem.linkIt.target }
            />
          )
        }
        
        if ( navBarItem.typeHandle === 'dropdown' ) {
          return (
            <NavBarDropdown
              key={ navBarItem.id }
              label={ navBarItem.label }
              icon={ navBarItem.icon }
              dropdownList={ navBarItem.dropdownList }
            />
          )
        }

        return null
      } ) }
    </StyledNavigationBar>
  )
}

const StyledNavigationBar = styled.div`
  display: flex;
  align-items: center;
  gap: 0.8rem;
  font-size: ${ p => p.theme.typo.sizes.small };

  > a {
    color: inherit;
    text-decoration: none;
  }

  @media ( max-width: 650px ) {
    > *:not( ${ StyledNavBarDropdown } ) {
      display: none;
    }
  }
`
