import styled, { css } from 'styled-components'

interface Stack {
  $top?: number | string,
  $bottom?: number | string,
  $flex?: boolean,
  $justifyContent?:
    'flex-start'
    | 'flex-end'
    | 'center'
    | 'space-between'
    | 'space-evenly'
    | 'space-around',
  $alignItems?:
    'flex-start'
    | 'flex-end'
    | 'center'
    | 'stretch',
  $between?: number,
  $height?: string,
}

export const getStackValue = stack => `calc( ${ stack } * var( --stack-basis ) )`

const Stack = styled.div<Stack>`
  position: relative;

  ${ p => p.$height && css`
    height: ${ p.$height };
  ` }

  ${ p => p.$flex && css`
    display: flex;
    flex-direction: column;
    justify-content: ${ p.$justifyContent };
    align-items: ${ p.$alignItems };

    ${ p.$between && css`
      gap: ${ getStackValue( p.$between ) };
    ` }
  ` }

  > * {
    margin-top: 0;
    margin-bottom: 0;
  }

  ${ p => !p.$flex && p.$between && typeof p.$between === 'number' && css`
    > * {
      padding-bottom: ${ getStackValue( p.$between ) };

      &:last-child {
        padding-bottom: 0;
      }
    }
  ` }

  ${ p => p.$top && typeof p.$top === 'number' && css`
    padding-top: ${ getStackValue( p.$top ) };
  ` }

  ${ p => p.$bottom && typeof p.$bottom === 'number' && css`
    position: relative;
    padding-bottom: ${ getStackValue( p.$bottom ) };
  ` }
`

Stack.defaultProps = {
  $justifyContent: 'flex-start',
  $alignItems: 'flex-start',
}

export default Stack

interface StackItem {
  $top?: number | 'auto',
  $bottom?: number | 'auto',
}

export const StackItem = styled.div<StackItem>`
  ${ p => p.$top && p.$top !== 'auto' && css`
    padding-top: ${ getStackValue( p.$top ) };
  ` }
  
  ${ p => p.$top && p.$top === 'auto' && css`
    padding-top: 0;
    margin-top: auto;
  ` }

  ${ p => p.$bottom && p.$bottom !== 'auto' && css`
    padding-bottom: ${ getStackValue( p.$bottom ) };
  ` }

  ${ p => p.$bottom && p.$bottom === 'auto' && css`
    padding-bottom: 0;
    margin-bottom: auto;
  ` }
`
