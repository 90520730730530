import Button from '../Button'

export default function NavBarButton( { 
  url,
  text,
  target, 
  color, 
  buttonStyle, 
} ) {
  return (
    <Button 
      href={ url }
      target={ target }
      outline={ buttonStyle !== 'primary' }
      color={ color }
      size="small"
    >
      { text }
    </Button>
  )
}
