import type { MutableRefObject } from 'react'
import { useEffect, useRef } from 'react'
import useKeyDown from './useKeyDown'
import useOnClickOutside from './useOnClickOutside'
import useFocusTrap from './useFocusTrap'
import usePreventBodyScroll from './usePreventBodyScroll'

interface UseModalTraits {
  isOpen: boolean,
  /** A function that closes the modal */
  close: () => void,
  /**
   * A react ref for the modal, used to manage focus
   * trapping and detecting clicks outside the modal
   */
  containerRef: MutableRefObject<HTMLElement>,
  /** Selector string for elements in the modal that are focusable */
  focusableElementsSelector?: string,
  /**
   * Additional elements to include in the focus trap
   */
  includeInFocusTrap?: [MutableRefObject<HTMLElement>] | [],
  /**
   * Selector string for an element that should be focussed on modal open.
   * If this returns multiple elements, the first is used.
   */
  focusOnOpen?: string,
  /**
   * Prevent <body> from scrolling when open
   * default: true
   */
  preventBodyScroll?: boolean,
}

const useModalTraits = ( {
  isOpen,
  close,
  containerRef,
  focusableElementsSelector = undefined,
  includeInFocusTrap = [],
  focusOnOpen = null,
  preventBodyScroll = true,
}: UseModalTraits ) => {
  const lastFocusBeforeOpen = useRef( null )

  // Close on Escape key
  useKeyDown( 'Escape', () => {
    close()
  }, [], !isOpen )

  // Close on click outside
  useOnClickOutside(
    containerRef,
    e => {
      e.preventDefault()
      close()
    },
    !isOpen,
  )

  // Trap focus
  useFocusTrap( containerRef, {
    isTrapped: isOpen,
    elementsSelector: focusableElementsSelector,
    additionalElements: includeInFocusTrap,
  } )

  // Prevent body scroll when open
  usePreventBodyScroll( preventBodyScroll && isOpen, containerRef.current )

  useEffect( () => {
    if ( isOpen ) {
      lastFocusBeforeOpen.current = document.activeElement

      if ( focusOnOpen ) {
        const el = containerRef.current?.querySelectorAll( focusOnOpen )[ 0 ] as HTMLElement
        el?.focus?.()
      }
    }
    else {
      lastFocusBeforeOpen.current?.focus()
    }

    return () => {
      lastFocusBeforeOpen.current?.focus()
    }
  }, [isOpen, focusOnOpen, containerRef] )
}

export default useModalTraits
